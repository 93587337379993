import React from 'react'
import { ClientLazy } from '~/components'

// const PDFDownloadLink = React.lazy(() =>
//   import('~/react-pdf.browser.js').then((module) => ({
//     default: module.PDFDownloadLink,
//   }))
// )

export default function PdfClientDownload({
  document,
  fileName,
  children,
}: {
  document: React.ReactElement
  fileName?: string
  children: React.ReactNode
}) {
  return (
    <ClientLazy
      component={() => import('./LazyPdfDownload')}
      document={document}
      fileName={fileName}>
      {children}
    </ClientLazy>
  )
}
